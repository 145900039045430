import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import PropTypes from "prop-types";
import styles from "./ModalEdit.module.css";
import Button from "../Project/ProjectComponents/Button";
import TagsComponent from "./ModalEditTagWindow";
import ErrorMessage from "./ModalEditError";
import {
  isValidProjectName,
  isHttpUrl,
  isFrequency,
  isValidFactoryName,
} from "../../utils/validationUtils";

export let projectId, setProjectId;
export let projectName, setProjectName;
export let factoryName, setFactoryName;
export let projectStatus, setProjectStatus;
export let projectDestination, setProjectDestination;
export let projectFrequency, setProjectFrequency;
export let tags, setTags;
export let errorMessages, setErrorMessages;

const ModalEdit = ({ isOpen, onClose, onAccept }) => {
  [errorMessages, setErrorMessages] = useState({});
  [projectId, setProjectId] = useState("");
  [projectName, setProjectName] = useState("");
  [factoryName, setFactoryName] = useState("");
  [projectStatus, setProjectStatus] = useState("Active");
  [projectDestination, setProjectDestination] = useState("");
  [projectFrequency, setProjectFrequency] = useState("");
  [tags, setTags] = useState([]);
  const [removedTags, setRemovedTags] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { accounts } = useMsal();
  const user = accounts.length > 0 ? accounts[0] : null;

  const clearFields = () => {
    setProjectId("");
    setProjectName("");
    setProjectDestination("");
    setProjectFrequency("");
    setFactoryName("");
    setTags([]);
    setRemovedTags([]);
    setErrorMessages({});
  };
  const closeModal = (e) => {
    onClose(e);
    clearFields();
  };

  const transformTags = (originalTags) => {
    return originalTags.map((tag) => ({
      id: tag.TagId,
      description: tag.TagDescription,
      type: tag.DataType,
      max: tag.MaxValue,
      min: tag.MinValue,
      name: tag.TagName,
    }));
  };

  const handleAccept = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    setIsLoading(true);
    const removedTagsData = {
      ProjectName: projectName,
      Tags: removedTags,
    };
    const transformedTags = transformTags(tags);
    const projectData = {
      ProjectId: projectId,
      ProjectName: projectName,
      Destination: projectDestination,
      Frequency: projectFrequency,
      Status: projectStatus,
      Date: new Date().toISOString(),
      User: user ? user.name : "Guest",
      Tags: transformedTags,
      Create: false,
    };
    const allData = {
      RemovedTags: removedTagsData,
      UpdatedProject: projectData,
    };
    await onAccept(allData);
    clearFields();
    setIsDisabled(false);
    setIsLoading(false);
  };
  useEffect(() => {
    const validProjectName = isValidProjectName(projectName);
    const validHttpUrl = isHttpUrl(projectDestination);
    const validFrequency = isFrequency(projectFrequency);
    const validFactoryName = isValidFactoryName(factoryName);

    const errors = {};
    if (!projectName) errors.projectName = "Project Name is required.";
    else if (!validProjectName.isValid)
      errors.projectName = validProjectName.message;

    if (!projectDestination)
      errors.projectDestination = "Project Destination is required.";
    else if (!validHttpUrl.isValid)
      errors.projectDestination = validHttpUrl.message;

    if (!projectFrequency) errors.projectFrequency = "Frequency is required.";
    else if (!validFrequency.isValid)
      errors.projectFrequency = validFrequency.message;

    if (tags.length === 0) errors.tags = "At least one tag is required.";
    setErrorMessages(errors);

    setIsDisabled(Object.keys(errors).length > 0);
    // eslint-disable-next-line
  }, [projectName, projectDestination, projectFrequency, factoryName, tags]);

  return (
    <>
      {isOpen && (
        <div className={styles.coverPage}>
          <div className={styles.myStyleModalAll}>
            <div className={styles.myStyleModalText}>
              <table className={styles.tableWidth}>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      width: "30%",
                      verticalAlign: "top",
                    }}
                  >
                    <span>Project Name</span>
                  </th>
                  <input
                    className={styles.project2}
                    type="text"
                    value={projectName}
                    readOnly
                  />
                  <div className={styles.errorContainer}>
                    {errorMessages.projectName ? (
                      <ErrorMessage message={errorMessages.projectName} />
                    ) : (
                      <div className={styles.placeholder}></div>
                    )}
                  </div>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      width: "30%",
                      verticalAlign: "top",
                    }}
                  >
                    <span>Project Destination</span>
                  </th>
                  <input
                    className={styles.project}
                    type="text"
                    value={projectDestination}
                    onChange={(e) => setProjectDestination(e.target.value)}
                  />
                  <div className={styles.errorContainer}>
                    {errorMessages.projectDestination ? (
                      <ErrorMessage
                        message={errorMessages.projectDestination}
                      />
                    ) : (
                      <div className={styles.placeholder}></div>
                    )}
                  </div>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      width: "30%",
                      verticalAlign: "top",
                    }}
                  >
                    <span>Prediction Frequency</span>
                  </th>
                  <input
                    className={styles.project}
                    type="text"
                    value={projectFrequency}
                    onChange={(e) => setProjectFrequency(e.target.value)}
                  />
                  <div className={styles.errorContainer}>
                    {errorMessages.projectFrequency ? (
                      <ErrorMessage message={errorMessages.projectFrequency} />
                    ) : (
                      <div className={styles.placeholder}></div>
                    )}
                  </div>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      width: "30%",
                      verticalAlign: "top",
                    }}
                  >
                    <span>Plant ID</span>
                  </th>
                  <input
                    className={styles.project2}
                    type="text"
                    value={factoryName}
                    readOnly
                  />
                  <div className={styles.errorContainer}>
                    {errorMessages.factoryName ? (
                      <ErrorMessage message={errorMessages.factoryName} />
                    ) : (
                      <div className={styles.placeholder}></div>
                    )}
                  </div>
                </tr>
              </table>
            </div>
            <br />
            <div className={styles.tagWindow}>
              <TagsComponent
                tags={tags}
                setTags={setTags}
                projectName={projectName}
                factoryName={factoryName}
                removedTags={removedTags}
                setRemovedTags={setRemovedTags}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              ></div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {errorMessages.tags && (
                  <ErrorMessage message={errorMessages.tags} />
                )}
              </div>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button type="primary" children="Cancel" onClick={closeModal} />
              <div style={{ display: "flex" }}>
                {isLoading ? <div className={styles.loader}></div> : ""}
                <Button
                  type={isDisabled ? "secondary" : "primary"}
                  children="Create"
                  onClick={handleAccept}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ModalEdit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default ModalEdit;
