import { urlWarmup } from "../Settings/url";

// Default retry and delay values
const DEFAULT_MAX_RETRIES = 2;
const DEFAULT_RETRY_DELAY_MS = 100;

export async function warmUpLambda(authToken, maxRetries = DEFAULT_MAX_RETRIES, retryDelayMs = DEFAULT_RETRY_DELAY_MS) {
  let attempt = 0;

  while (attempt < maxRetries) {
    try {
      const response = await fetch(urlWarmup, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 401) {
        console.error("401 Unauthorized - Clearing session and refreshing...");
        sessionStorage.clear(); // Clear session storage
        window.location.reload(); // Refresh the page
        return false; // Exit the function after handling 401
      }

      if (response.ok) {
        const responseText = await response.text();
        console.log(`Lambda warmed up successfully on attempt ${attempt + 1}:`, responseText);
        return true;
      } else {
        console.warn(`Warm-up attempt ${attempt + 1} failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error(`Error during warm-up attempt ${attempt + 1}:`, error);
    }

    attempt++;
    if (attempt < maxRetries) {
      console.log(`Retrying warm-up in ${retryDelayMs}ms...`);
      await delay(retryDelayMs);
    }
  }

  console.error("Failed to warm up Lambda after maximum attempts.");
  return false; // Indicate failure
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
