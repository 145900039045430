import React, { useState, useEffect } from "react";
import Button from "./Button";
import styles from "./ProjectList.module.css";
import { urlProjects } from "../../../Settings/url";
import { getInfo } from "./Search";
import { warmUpLambda } from "../../../utils/warmUpLambda";

import {
  setProjectId,
  setProjectName,
  setFactoryName,
  setProjectDestination,
  setProjectFrequency,
  setTags,
} from "../../ModalWindows/ModalEdit";
import ModalEdit from "../../ModalWindows/ModalEdit";
import ModalShutdownConfirmation from "../../ModalWindows/ModalShutdownConfirmation";
import { setIsTableButtonVisible } from "./DownloadDataButton";
import { useMsal } from "@azure/msal-react";

export let isTableVisible, setIsTableVisible;
export let projectMap, setProjectMap;
export let ProjectOpener;

const ProjectList = () => {
  [isTableVisible, setIsTableVisible] = useState(false);
  [projectMap, setProjectMap] = useState([]);
  const [isProject, setProject] = useState(null);
  const [openProjectId, setOpenProjectId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("Active");
  const [isModalEditOpen, setModalEditOpen] = useState(false);
  const [isModalShutdownConfirmationOpen, setModalShutdownConfirmationOpen] =
    useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDisabledEdit, setIsDisabledEdit] = useState(false);
  const [isDisabledShutdown, setIsDisabledShutdown] = useState(false);
  const { accounts } = useMsal();
  const userToken = accounts[0].idToken;

  useEffect(() => {
    if (projectMap && projectMap[0]) {
      setCurrentStatus(projectMap[0].Status);
    }
  }, [projectMap]);

  // Pass userToken to fetchUpdatedData
  async function fetchUpdatedData(projectName) {
    await getInfo(projectName, userToken);
  }

  useEffect(() => {
    if (currentStatus === "Shutdown") {
      setIsDisabledAll(true);
    } else if (currentStatus !== "Active") {
      setIsDisabledEdit(true);
      setIsDisabledShutdown(false);
      setIsDisabled(false);
    } else if (currentStatus === "Active") {
      setIsDisabledAll(false);
    }
  }, [currentStatus]);

  ProjectOpener = (status) => {
    setCurrentStatus(`${status}`);
    setOpenProjectId();
  };

  const handleProjectClick = (projectId) => {
    setOpenProjectId(projectId === openProjectId ? null : projectId);
  };

  async function fetchUpdatedData(projectName, userToken) {
    try {
      await getInfo(projectName, userToken);
    } catch (error) {
      console.error("Error fetching updated project data:", error);
    }
  }

  const setIsDisabledAll = (value) => {
    setIsDisabled(value);
    setIsDisabledEdit(value);
    setIsDisabledShutdown(value);
  };

  const handleStatusToggle = async (project) => {
    setIsDisabledAll(true);
    try {
      const newStatus = project.Status === "Active" ? "Pause" : "Active";
      await handleStatusUpdate(project, newStatus);
    } finally {
      setIsDisabledAll(false);
    }
  };

  const handleShutdown = async (project) => {
    setIsDisabledAll(true);
    try {
      const newStatus = "Shutdown";
      await handleStatusUpdate(project, newStatus);
    } finally {
      setIsDisabledAll(false);
      setModalShutdownConfirmationOpen(false);
    }
  };

  const handleStatusUpdate = async (project, newStatus) => {
    try {
      let response = await fetch(
        `${urlProjects}/${project.ProjectId}/status?status=${newStatus}`,
        {
          method: "PUT",
          redirect: "follow",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.ok) {
        project.Status = newStatus;
        setCurrentStatus(newStatus);
      } else {
        console.error("Failed to update project status");
        alert("An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Network or server error:", error);
      alert("Failed to communicate with the server. Please try again.");
    }
  };

  const handleEditClick = (project, e) => {
    e.preventDefault();
    setProjectId(project.ProjectId);
    setProjectName(project.ProjectName);
    setProjectDestination(project.Destination);
    setProjectFrequency(project.Frequency);
    const factoryName = project.tags[0].TagName.split(".");
    setFactoryName(factoryName[2]);
    setTags(project.tags);
    setModalEditOpen(true);
  };

  const handleAcceptModalEdit = async (projectData) => {
    const { RemovedTags, UpdatedProject } = projectData;

    try {
      await warmUpLambda(userToken);
      // 1. Update Project Details
      if (UpdatedProject.Destination || UpdatedProject.Frequency) {
        const response = await fetch(
          `${urlProjects}/${UpdatedProject.ProjectId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify({
              destination: UpdatedProject.Destination,
              frequency: UpdatedProject.Frequency,
            }),
          }
        );
        if (!response.ok) {
          console.error("Failed to update project");
        } else {
          console.log("Project updated successfully");
        }
      }
      // 2. Add New Tags
      const newTags = UpdatedProject.Tags.filter((tag) => !tag.id);
      if (newTags.length > 0) {
        console.log(`New Tags: ${newTags}`);
        const response = await fetch(
          `${urlProjects}/${UpdatedProject.ProjectId}/tags`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(newTags),
          }
        );
        if (!response.ok) {
          console.error(`Failed to add tag: ${newTags}`);
        } else {
          console.log(`Added new tag: ${newTags}`);
        }
      }

      // 3. Update Existing Tags
      const updatedTags = UpdatedProject.Tags.filter((tag) => tag.id);
      console.log(
        `UpdatedProject.Tags: ${JSON.stringify(UpdatedProject.Tags)}`
      );
      if (updatedTags.length > 0) {
        for (const updatedTag of updatedTags) {
          try {
            const response = await fetch(
              `${urlProjects}/${UpdatedProject.ProjectId}/tags/${updatedTag.id}`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userToken}`,
                },
                body: JSON.stringify({
                  name: updatedTag.name,
                  type: updatedTag.type,
                  min: updatedTag.min,
                  max: updatedTag.max,
                }),
              }
            );

            if (!response.ok) {
              console.error(`Failed to update tag with ID: ${updatedTag.id}`);
            } else {
              console.log(`Successfully updated tag with ID: ${updatedTag.id}`);
            }
          } catch (err) {
            console.error(
              `Error updating tag with ID: ${updatedTag.TagId}`,
              err
            );
          }
        }
      }

      // 4. Remove Tags
      if (RemovedTags.Tags && RemovedTags.Tags.length > 0) {
        const deletePayload = {
          tags: RemovedTags.Tags,
        };
        console.log(`Deleted tags: ${JSON.stringify(deletePayload)}`);
        const response = await fetch(
          `${urlProjects}/${UpdatedProject.ProjectId}/tags`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify(deletePayload),
          }
        );
        if (!response.ok) {
          console.error("Failed to delete tags:", deletePayload);
        } else {
          console.log("Deleted tags:", deletePayload);
        }
      }

      // Refetch updated data
      await fetchUpdatedData(UpdatedProject.ProjectName, userToken);
    } catch (error) {
      console.error("Error processing updates:", error);
    } finally {
      setModalEditOpen(false);
    }
  };

  const closeModalEdit = (e) => {
    e.preventDefault();
    setModalEditOpen(false);
  };

  const confirmShutdown = (project) => {
    setProject(project);
    setModalShutdownConfirmationOpen(true);
  };

  const closeModalShutdownConfirmation = (e) => {
    e.preventDefault();
    setModalShutdownConfirmationOpen(false);
  };

  const tableHeader = () => {
    return (
      <React.Fragment key="headerFragment">
        <tr>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            ProjectID
          </th>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            Name
          </th>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            Destination
          </th>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            Frequency
          </th>
          <th
            className={`${styles.thStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            Actions
          </th>
        </tr>
      </React.Fragment>
    );
  };

  const tableBody = () => {
    return projectMap.map((project) => (
      <React.Fragment key={project.Id}>
        <tr
          className={
            openProjectId === project.Id
              ? {
                  ...`${styles.tdStyles} ${
                    !(currentStatus === "Active") ? styles.tableDisabled : ""
                  }`,
                }
              : `${styles.tdStyles} ${
                  !(currentStatus === "Active") ? styles.tableDisabled : ""
                }`
          }
        >
          <td
            className={`${styles.tdStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
            onClick={() => handleProjectClick(project.Id)}
          >
            {project.ProjectId}
          </td>
          <td
            className={`${styles.tdStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
            onClick={() => handleProjectClick(project.Id)}
          >
            {project.ProjectName}
          </td>
          <td
            className={`${styles.tdStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
            onClick={() => handleProjectClick(project.Id)}
          >
            {project.Destination}
          </td>
          <td
            className={`${styles.tdStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
            onClick={() => handleProjectClick(project.Id)}
          >
            {project.Frequency}
          </td>
          <td
            className={`${styles.tdButtonStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            <Button
              type={isDisabledEdit ? "secondary" : "primary"}
              children="Edit"
              onClick={(e) => handleEditClick(project, e)}
              disabled={isDisabledEdit}
            />
            <Button
              type={isDisabled ? "secondary" : "primary"}
              children={currentStatus === "Active" ? "Pause" : "Re-Activate"}
              onClick={(e) => {
                handleStatusToggle(project);
              }}
              disabled={isDisabled}
            />
            <Button
              type={isDisabledShutdown ? "secondary" : "primary"}
              children="Shutdown"
              onClick={(e) => {
                confirmShutdown(project);
              }}
              disabled={isDisabledShutdown}
            />
          </td>
        </tr>
        {openProjectId === project.Id && (
          <tr>
            <td colSpan="5">
              <table style={{ minWidth: "100%" }}>
                <thead>
                  <tr>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      Tag ID
                    </th>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      Tag Name
                    </th>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      DataType
                    </th>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      Min Value
                    </th>
                    <th
                      className={`${styles.thStyles} ${
                        !(currentStatus === "Active")
                          ? styles.tableDisabled
                          : ""
                      }`}
                    >
                      Max Value
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {project.tags.map((tag, index) => (
                    <tr key={index}>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.TagId}
                      </td>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.TagName}
                      </td>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.DataType}
                      </td>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.MinValue}
                      </td>
                      <td
                        className={`${styles.tdStyles} ${
                          !(currentStatus === "Active")
                            ? styles.tableDisabled
                            : ""
                        }`}
                      >
                        {tag.MaxValue}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      {isTableVisible && (
        <>
          <table
            className={`${styles.tableStyles} ${
              !(currentStatus === "Active") ? styles.tableDisabled : ""
            }`}
          >
            <thead>{tableHeader()}</thead>
            <tbody>{tableBody()}</tbody>
          </table>
          <div>
            <ModalEdit
              isOpen={isModalEditOpen}
              onClose={closeModalEdit}
              onAccept={handleAcceptModalEdit}
            />
            <ModalShutdownConfirmation
              isOpen={isModalShutdownConfirmationOpen}
              onClose={closeModalShutdownConfirmation}
              onAccept={handleShutdown}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectList;
