import React, { useState, useEffect } from "react";
import { thStyles } from "./ModalStyles";
import PropTypes from "prop-types";
import styles from "./ModalTagWindow.module.css";
import Button2 from "../Project/ProjectComponents/Button2";
import ErrorIcon from "./ModalTagError";
import {
  isValidProjectName,
  isValidFactoryName,
  isValidTagName,
  isValidnewPredictionType,
} from "../../utils/validationUtils";

export let newTagId, setNewTagId;
export let newTagName, setNewTagName;
export let tagName, settagName;
export let newPredictionType, setnewPredictionType;
export let newPredictionMin, setnewPredictionMin;
export let newPredictionMax, setnewPredictionMax;
export let factoryMatch;
export let projectNameMatch;
export let itemNameMatch;
export let updateFactoryNameInTags;
export let updateProjectNameInTags;

const TagsComponent = ({ tags, setTags, projectName, factoryName }) => {
  [newTagId, setNewTagId] = useState("");
  [newTagName, setNewTagName] = useState("");
  [tagName, settagName] = useState("");
  [newPredictionType, setnewPredictionType] = useState("");
  [newPredictionMin, setnewPredictionMin] = useState("");
  [newPredictionMax, setnewPredictionMax] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  let errors = {};

  const validateForm = () => {
    const validProjectName = isValidProjectName(projectName);
    const validFactoryName = isValidFactoryName(factoryName);
    const validTagName = isValidTagName(tagName);
    const validnewPredictionType = isValidnewPredictionType(newPredictionType);

    const isValidnewPredictionMin =
      !isNaN(newPredictionMin) && newPredictionMin.trim() !== "";
    const isValidnewPredictionMax =
      !isNaN(newPredictionMax) &&
      newPredictionMax.trim() !== "" &&
      parseFloat(newPredictionMax) >= parseFloat(newPredictionMin);
    factoryMatch = tags.every((tag) => {
      const parts = tag.name.split(".");
      return parts[2] === factoryName;
    });
    projectNameMatch = tags.every((tag) => {
      const parts = tag.name.split(".");
      return parts[3] === projectName;
    });
    itemNameMatch = tags.every((tag) => {
      const parts = tag.name.split(".");
      return parts[4] !== tagName;
    });
    if (!projectName.trim()) errors.projectName = "Project Name is required.";
    else if (!validProjectName.isValid)
      errors.projectName = validProjectName.message;
    if (!projectNameMatch)
      errors.projectName =
        "Project Name must match the Project Name of the Factory.";
    if (!factoryName.trim()) errors.factoryName = "Plant ID Name is required.";
    else if (!validFactoryName.isValid)
      errors.factoryName = validFactoryName.message;
    if (!factoryMatch)
      errors.factoryName = "Plant ID must match the Plant ID of the Factory.";
    // if (!tagName.trim()) errors.tagName = "Tag Name is required.";
    else if (!validTagName.isValid) errors.tagName = validTagName.message;
    if (!itemNameMatch)
      errors.tagName =
        "Tag Name must not match any other Tag Names in the Project.";
    else if (!validnewPredictionType.isValid)
      errors.newPredictionType = validnewPredictionType.isValid;
    else if (!isValidnewPredictionType)
      errors.newPredictionType =
        "Prediction Type must be 'int', 'float', or 'boolean'.";
    if (!newPredictionMin.trim())
      errors.newPredictionMin = "Prediction Minimum Value is required.";
    else if (!isValidnewPredictionMin)
      errors.newPredictionMin = "Prediction Minimum Value must be a number.";
    if (!newPredictionMax.trim())
      errors.newPredictionMax = "Prediction Maximum Value is required.";
    else if (!isValidnewPredictionMax)
      errors.newPredictionMax =
        "Prediction Maximum Value must be a number greater than or equal to Minimum Value.";

    setErrorMessages(errors);
    setIsFormValid(Object.keys(errors).length === 0);
  };

  updateFactoryNameInTags = (newFactoryName) => {
    const updatedTags = tags.map((tag) => ({
      ...tag,
      name: tag.name.replace(
        /CLOUD\.PLC\.\w*\./,
        `CLOUD.PLC.${newFactoryName}.`
      ),
    }));
    setTags(updatedTags);
  };

  updateProjectNameInTags = (newProjectName) => {
    const updatedTags = tags.map((tag) => ({
      ...tag,
      name: tag.name.replace(
        /CLOUD\.PLC\.(\w*)\.\w*\./,
        `CLOUD.PLC.$1.${newProjectName}.`
      ),
    }));
    setTags(updatedTags);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line
  }, [
    projectName,
    factoryName,
    tagName,
    newPredictionType,
    newPredictionMin,
    newPredictionMax,
  ]);

  const handleAddTag = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      validateForm();
      return;
    }
    if (
      projectName.trim() !== "" &&
      factoryName.trim() !== "" &&
      tagName.trim() !== "" &&
      newPredictionType.trim() !== "" &&
      newPredictionMin.trim() !== "" &&
      newPredictionMax.trim() !== ""
    ) {
      if (isFormValid) {
        newTagName = `CLOUD.PLC.${factoryName}.${projectName}.${tagName}`;
        const newTag = {
          name: newTagName,
          type: newPredictionType,
          min: newPredictionMin,
          max: newPredictionMax,
        };
        setTags([...tags, newTag]);
        setNewTagName("");
        settagName("");
        setnewPredictionType("");
        setnewPredictionMin("");
        setnewPredictionMax("");
      }
    }
  };

  const handleRemoveTag = (tag, e) => {
    e.preventDefault();
    const newTags = [...tags];
    const nameIndex = newTags.findIndex((t) => t.name === tag.name);
    if (nameIndex !== -1) {
      newTags.splice(nameIndex, 1);
      setTags(newTags);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          top: "92%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {Object.values(errorMessages).map((error, index) => (
          <ErrorIcon error={error} index={index} />
        ))}
      </div>
    </div>
  );
};

TagsComponent.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func,
  newTagName: PropTypes.string,
  setNewTagName: PropTypes.func,
  newPredictionType: PropTypes.string,
  setnewPredictionType: PropTypes.func,
  newPredictionMin: PropTypes.string,
  setnewPredictionMin: PropTypes.func,
  newPredictionMax: PropTypes.string,
  setnewPredictionMax: PropTypes.func,
};

export default TagsComponent;
