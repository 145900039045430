import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./AddTagPopup.module.css";
import Button2 from "../Project/ProjectComponents/Button2";
import { newTagName } from "./ModalTagWindow";

const AddTagPopup = ({
  isOpen,
  onClose,
  onAddTag,
  factoryName,
  projectName,
}) => {
  const [newTag, setNewTag] = useState({
    TagName: "",
    TagDescription: "",
    DataType: "",
    MinValue: "",
    MaxValue: "",
  });

  const [userTagName, setUserTagName] = useState("");

  if (!isOpen) return null; // Do not render if the popup is not open

  const handleAdd = () => {
    if (
      newTag.TagName &&
      newTag.TagDescription &&
      newTag.DataType &&
      newTag.MinValue &&
      newTag.MaxValue
    ) {
      onAddTag(newTag); // Add the tag via the parent callback
      setNewTag({
        TagName: "",
        TagDescription: "",
        DataType: "",
        MinValue: "",
        MaxValue: "",
      }); // Reset fields
      onClose(); // Close the popup
    } else {
      alert("Please fill all fields.");
    }
  };

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popup}>
        <h3>Create New Tag</h3>
        <div>
          <label>
            Tag Name:
            <div
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "4px",
                margin: "15px 0",
                overflow: "hidden",
              }}
            >
              {/* Prefix styled to look like part of the input */}
              <span
                style={{
                  color: "#323232",
                  padding: "8px 12px",
                  lineHeight: "1.5",
                  fontSize: "14px",
                  backgroundColor: "#f9f9f9",
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                CLOUD.PLC.{factoryName}.{projectName}.
              </span>
              {/* Input field */}
              <input
                type="text"
                value={userTagName}
                onChange={(e) => {
                  const newTagName = `CLOUD.PLC.${factoryName}.${projectName}.${e.target.value}`;
                  setUserTagName(e.target.value);
                  setNewTag({ ...newTag, TagName: newTagName });
                }}
                placeholder="Enter Tag Name"
                style={{
                  flex: "1",
                  border: "none",
                  padding: "8px 12px",
                  fontSize: "14px",
                }}
              />
            </div>
          </label>
        </div>
        <div>
          <div>
            <label>
              Tag Description:
              <input
                style={{ color: "#323232" }}
                type="text"
                value={newTag.TagDescription}
                onChange={(e) =>
                  setNewTag({ ...newTag, TagDescription: e.target.value })
                }
                placeholder="Write a Description"
              />
            </label>
          </div>
          <label>
            Prediction Type:
            <select
              style={{ color: "#323232" }}
              value={newTag.DataType}
              onChange={(e) =>
                setNewTag({ ...newTag, DataType: e.target.value })
              }
            >
              <option value="" disabled>
                Select Prediction Type
              </option>
              <option value="int">int</option>
              <option value="float">float</option>
              <option value="boolean">boolean</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Min Value:
            <input
              style={{ color: "#323232" }}
              type="text"
              value={newTag.MinValue}
              onChange={(e) =>
                setNewTag({ ...newTag, MinValue: e.target.value })
              }
              placeholder="Enter Min Value"
            />
          </label>
        </div>
        <div>
          <label>
            Max Value:
            <input
              style={{ color: "#323232" }}
              type="text"
              value={newTag.MaxValue}
              onChange={(e) =>
                setNewTag({ ...newTag, MaxValue: e.target.value })
              }
              placeholder="Enter Max Value"
            />
          </label>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button2
            type="warning"
            children="Cancel"
            onClick={() => {
              setNewTag({
                TagName: "",
                TagDescription: "",
                DataType: "",
                MinValue: "",
                MaxValue: "",
              }); // Reset fields
              onClose(); // Close the popup
            }}
          />
          <Button2 type="primary" children="Add" onClick={handleAdd} />
        </div>
      </div>
    </div>
  );
};

AddTagPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddTag: PropTypes.func.isRequired,
  factoryName: PropTypes.string,
  projectName: PropTypes.string,
};

export default AddTagPopup;
