export const isValidProjectName = (value) => {
    const regex = /\b^[A-Za-z][A-Za-z0-9_-]*[A-Za-z0-9]$\b/;
    const isValid = regex.test(value);

    if (isValid) {
        return {
            isValid: true,
        };
    } else {
        return {
            isValid: false,
            message: "Project name must start with a letter, and may contain only letters, numbers, underscores, or hyphens.",
            color: "red",
        };
    }
};

export const isValidTagProjectName = (value) => {
    const regex = /^[\w]+_[\w]+_[\w]+_[\w]+$/i;
    return regex.test(value);
};

export const isFourWordsSeparatedByUnderscore = (value) => {
    const regex = /^[\w]+_[\w]+_[\w]+_[\w]+$/i;
    return regex.test(value);
};

export const isHttpUrl = (value) => {
    const regex = /^(https?:\/\/)(?!.*:[0-9])([a-zA-Z0-9.-]+)\.([a-zA-Z0-9-]+)(\/[a-zA-Z0-9-_]+)*$/i;
    const isValid = regex.test(value);

    if (isValid) {
        return {
            isValid: true,
        };
    } else {
        return {
            isValid: false,
            message: "Project Destination must be a valid URL.",
        }
    }
}

export const isFrequency = (value) => {
    const regex = /^(?:\d+[mdhs])$/i;
    const isValid = regex.test(value);

    if (isValid) {
        return {
            isValid: true,
        };
    } else {
        return {
            isValid: false,
            message: "Frequency must be in the format '1d', '2h', etc.",
        }
    }
}

export const isValidFactoryName = (value) => {
    const regex = /^[A-Z]+[A-Z\d]*$/;
    const isValid = regex.test(value);

    if (isValid) {
        return {
            isValid: true,
        };
    } else {
        return {
            isValid: false,
            message: "Plant ID must start with one or more uppercase letters and contain only uppercase letters and digits.",
        };
    }
};



export const isValidTagName = (value) => {
    const regex =  /^[a-zA-Z0-9]+[a-zA-Z0-9_]*(?<!_)$/;
    const isValid = regex.test(value);

    if (isValid) {
        return {
            isValid: true,
        };
    } else {
        return {
            isValid: false,
            message: "Example: Requared [At least one letter or digit], Optional [More letters, digits or _], Can't end with [_]",
        }
    }
}

export const isValidnewPredictionType = (value) => {
    const regex = /^(int|float|boolean)$/;
    const isValid = regex.test(value);

    if (isValid) {
        return {
            isValid: true,
        };
    } else {
        return {
            isValid: false,
            message: "Prediction Type must be 'int', 'float', or 'boolean'.",
        }
    }
}
