import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Project from './Components/Project/Project';
import Login from './Components/Login/Login';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './Components/Login/authConfig';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

function App() {
  return (
    <MsalProvider instance={new PublicClientApplication(msalConfig)}>
        <AuthenticatedTemplate>
            <Router>
              <div className="app">
                <div style={{minWidth: "80%", margin: "0 10%"}}>
                  <Routes>
                    <Route path="/" element={<Project />} />
                  </Routes>
                </div>
              </div>
            </Router>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Router>
            <div className="app">
              <div className="content">
                <Routes>
                  <Route path="/" element={<Login />} />
                </Routes>
              </div>
            </div>
          </Router>
        </UnauthenticatedTemplate>
    </MsalProvider>
  );
}

export default App;
