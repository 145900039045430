import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
      authority: 'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204', // ICL 
      clientId: 'd7c761fc-bf32-4511-a626-3e13710496cb', // ICL dev
      // clientId: '464e893b-c8cd-4430-9dde-30100ba11823', // ICL prod
      // clientId: 'a5038015-e035-4b1c-a088-cb254dad60e8', //Bynet
      // authority: 'https://login.microsoftonline.com/b5f3202f-73b3-40a8-a61e-596ab18836ea', //Bynet

    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
        logLevel: LogLevel.Verbose,
      },
    },
  };
  
// clientId: 'd7c761fc-bf32-4511-a626-3e13710496cb', // ICL dev
// clientId: '464e893b-c8cd-4430-9dde-30100ba11823', // ICL prod
// clientId: `${process.env.REACT_APP_CLIENT_ID}` || '',
// authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID || ''}`,
// clientId: 'a5038015-e035-4b1c-a088-cb254dad60e8', //Bynet
// authority: 'https://login.microsoftonline.com/b5f3202f-73b3-40a8-a61e-596ab18836ea', //Bynet