import React from "react";
import { useMsal } from "@azure/msal-react";
import styles from "./AzureADButton.module.css";

const AzureADButton = () => {
  const { instance } = useMsal();
  const handleLogin = async () => {
    try {
      await instance.loginPopup();
    } catch (error) {
      console.log("Authentication error:", error);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        textAlign: "center",
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      }}
    >
      <div
        style={{ marginBottom: "20px", fontSize: "18px", lineHeight: "1.5" }}
      >
        <p>Welcome back!</p>
        <p>
          It looks like you're not signed in. Please log in to access your
          account.
        </p>
      </div>
      <button
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          backgroundColor: "#0078D4",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "background-color 0.3s, transform 0.2s",
        }}
        onClick={handleLogin}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#005A9E")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#0078D4")}
        onMouseDown={(e) => (e.target.style.transform = "scale(0.95)")}
        onMouseUp={(e) => (e.target.style.transform = "scale(1)")}
      >
        Login with Azure AD
      </button>
    </div>
  );
};

export default AzureADButton;
