import React, { useEffect } from "react";
import styles from "./Project.module.css";
import Navbar from "./ProjectComponents/Navbar";
import { warmUpLambda } from "../../utils/warmUpLambda";
import NewProject from "./ProjectComponents/NewProject";
import ProjectList from "./ProjectComponents/ProjectList";
import DownloadDataButton from "./ProjectComponents/DownloadDataButton";
import ProjectLogo from "./ProjectComponents/ProjectLogo";
import ICLogo from "./ProjectComponents/ICLogo.png";
import { useMsal } from "@azure/msal-react";

const Project = () => {
  const { accounts } = useMsal();
  const userToken = accounts.length > 0 ? accounts[0].idToken : null;

  useEffect(() => {
    if (userToken) {
      warmUpLambda(userToken)
        .then(() => console.log("Lambda warmed up successfully."))
        .catch((err) => console.error("Error warming up Lambda:", err));
    } else {
      console.error("User token not available for Lambda warm-up.");
    }
  }, [userToken]);

  return (
    <>
      <Navbar />
      <NewProject />
      <div className={styles.myStyleAll}>
        <ProjectList />
        <DownloadDataButton />
      </div>
      <div>
        <ProjectLogo src={ICLogo} />
      </div>
    </>
  );
};

export default Project;
