import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import PropTypes from "prop-types";
import styles from "./ModalCreate.module.css";
import Button from "../Project/ProjectComponents/Button";
import TagsComponent from "./ModalTagWindow";
import Button2 from "../Project/ProjectComponents/Button2";
import ErrorMessage from "./ModalCreateError";
import AddTagPopup from "./AddTagPopup";
import ErrorIcon from "./ModalTagError";
import { urlSearch, urlProjects } from "../../Settings/url";
import { getInfo } from "../Project/ProjectComponents/Search";
import {
  isValidProjectName,
  isHttpUrl,
  isFrequency,
  isValidFactoryName,
} from "../../utils/validationUtils";
import {
  factoryMatch,
  updateFactoryNameInTags,
  updateProjectNameInTags,
} from "./ModalTagWindow";

export let projectName, setProjectName;
export let factoryName, setFactoryName;
export let errorMessages, setErrorMessages;

const ModalCreate = ({ isOpen, onClose, onAccept }) => {
  [errorMessages, setErrorMessages] = useState({});
  [projectName, setProjectName] = useState("");
  [factoryName, setFactoryName] = useState("");
  const [projectStatus] = useState("Active");
  const [projectDestination, setProjectDestination] = useState("");
  const [projectFrequency, setProjectFrequency] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const { accounts } = useMsal();
  const user = accounts.length > 0 ? accounts[0] : null;
  const userToken = accounts[0].idToken;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleAddTag = (newTag) => {
    if (
      !newTag.TagName ||
      !newTag.TagDescription ||
      !newTag.DataType ||
      !newTag.MinValue ||
      !newTag.MaxValue
    ) {
      alert("All fields are required to create a tag.");
      return;
    }

    // Check for duplicate tag names
    const duplicateTag = tags.find((tag) => tag.TagName === newTag.TagName);
    if (duplicateTag) {
      alert("Tag with the same name already exists.");
      return;
    }

    // setTags([...tags, formattedTag]); // Add new tag to the list
    setTags([...tags, newTag]); // Add new tag to the list
  };

  const clearFields = () => {
    setProjectName("");
    setProjectDestination("");
    setProjectFrequency("");
    setFactoryName("");
    setTags([]);
    setErrorMessages({});
  };
  const closeModal = (e) => {
    onClose(e);
    clearFields();
  };

  const handleAccept = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    setIsLoading(true);

    try {
      const isProjectNameTaken = await getInfo(projectName, userToken);

      if (isProjectNameTaken) {
        setErrorMessages({ projectName: "Project name already exists" });
        return;
      }

      // Transform the tags array to the required format
      const formattedTags = tags.map((tag) => ({
        name: tag.TagName,
        description: tag.TagDescription,
        type: tag.DataType,
        min: parseFloat(tag.MinValue),
        max: parseFloat(tag.MaxValue),
      }));

      const projectData = {
        name: projectName,
        destination: projectDestination,
        frequency: projectFrequency,
        tags: formattedTags, // Use the transformed tags array
      };

      // Log the payload for debugging
      console.log("Payload being sent:", JSON.stringify(projectData, null, 2));

      await onAccept(projectData);
    } catch (err) {
      console.error("Error creating project:", err);
    } finally {
      clearFields();
      setIsDisabled(false);
      setIsLoading(false);
    }
  };

  const handleProjectNameChange = (e) => {
    const newProjectName = e.target.value;
    setProjectName(newProjectName);
    updateProjectNameInTags(newProjectName);
  };

  const handleFactoryNameChange = (e) => {
    const newFactoryName = e.target.value;
    setFactoryName(newFactoryName);
    updateFactoryNameInTags(newFactoryName);
  };

  useEffect(() => {
    const validProjectName = isValidProjectName(projectName);
    const validHttpUrl = isHttpUrl(projectDestination);
    const validFrequency = isFrequency(projectFrequency);
    const validFactoryName = isValidFactoryName(factoryName);

    const errors = {};
    if (!projectName) errors.projectName = "Project Name is required.";
    else if (!validProjectName.isValid)
      errors.projectName = validProjectName.message;

    if (!projectDestination)
      errors.projectDestination = "Project Destination is required.";
    else if (!validHttpUrl.isValid)
      errors.projectDestination = validHttpUrl.message;

    if (!projectFrequency) errors.projectFrequency = "Frequency is required.";
    else if (!validFrequency.isValid)
      errors.projectFrequency = validFrequency.message;

    if (tags.length === 0) errors.tags = "At least one tag is required.";

    setErrorMessages(errors);

    setIsDisabled(Object.keys(errors).length > 0);
  }, [
    projectName,
    projectDestination,
    projectFrequency,
    factoryName,
    tags,
    factoryMatch,
  ]);

  return (
    <>
      {isOpen && (
        <div className={styles.coverPage}>
          <div className={styles.myStyleModalAll}>
            <div className={styles.myStyleModalText}>
              <table className={styles.tableWidth}>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      width: "30%",
                      verticalAlign: "top",
                    }}
                  >
                    <span>Project Name</span>
                  </th>
                  <input
                    className={styles.project}
                    type="text"
                    value={projectName}
                    onChange={handleProjectNameChange}
                  />
                  <div className={styles.errorContainer}>
                    {errorMessages.projectName ? (
                      <ErrorMessage message={errorMessages.projectName} />
                    ) : (
                      <div className={styles.placeholder}></div>
                    )}
                  </div>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      width: "30%",
                      verticalAlign: "top",
                    }}
                  >
                    <span>Project Destination</span>
                  </th>
                  <input
                    className={styles.project}
                    type="text"
                    value={projectDestination}
                    onChange={(e) => setProjectDestination(e.target.value)}
                  />
                  <div className={styles.errorContainer}>
                    {errorMessages.projectDestination ? (
                      <ErrorMessage
                        message={errorMessages.projectDestination}
                      />
                    ) : (
                      <div className={styles.placeholder}></div>
                    )}
                  </div>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      width: "30%",
                      verticalAlign: "top",
                    }}
                  >
                    <span>Prediction Frequency</span>
                  </th>
                  <input
                    className={styles.project}
                    type="text"
                    value={projectFrequency}
                    onChange={(e) => setProjectFrequency(e.target.value)}
                  />
                  <div className={styles.errorContainer}>
                    {errorMessages.projectFrequency ? (
                      <ErrorMessage message={errorMessages.projectFrequency} />
                    ) : (
                      <div className={styles.placeholder}></div>
                    )}
                  </div>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      width: "30%",
                      verticalAlign: "top",
                    }}
                  >
                    <span>Plant ID</span>
                  </th>
                  <input
                    className={styles.project}
                    type="text"
                    value={factoryName}
                    onChange={handleFactoryNameChange}
                  />
                  <div className={styles.errorContainer}>
                    {errorMessages.factoryName ? (
                      <ErrorMessage message={errorMessages.factoryName} />
                    ) : (
                      <div className={styles.placeholder}></div>
                    )}
                  </div>
                </tr>
              </table>
            </div>
            <br />
            {/* Table */}
            {tags.length > 0 && ( // Conditionally render the table
              <table className={styles.tableStyles}>
                <thead>
                  <tr>
                    <th style={{ width: "60%", color: "white" }}>Name</th>
                    <th style={{ width: "10%", color: "white" }}>Type</th>
                    <th style={{ width: "10%", color: "white" }}>Min Value</th>
                    <th style={{ width: "10%", color: "white" }}>Max Value</th>
                    <th style={{ width: "20%", color: "white" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {tags.map((tag, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          className={styles.tagInput}
                          value={tag.TagName}
                          readOnly
                          style={{
                            width: "100%",
                            border: "none",
                            backgroundColor: "transparent",
                            color: "#fff",
                          }}
                        />
                      </td>
                      <td>
                        <select
                          className={styles.tagSelect}
                          value={tag.DataType}
                          onChange={(e) =>
                            setTags((prevTags) =>
                              prevTags.map((t, i) =>
                                i === index
                                  ? { ...t, DataType: e.target.value }
                                  : t
                              )
                            )
                          }
                        >
                          <option value="int">int</option>
                          <option value="float">float</option>
                          <option value="boolean">boolean</option>
                        </select>
                      </td>
                      <td>
                        <input
                          className={styles.tagInput}
                          type="number"
                          value={tag.MinValue}
                          onChange={(e) =>
                            setTags((prevTags) =>
                              prevTags.map((t, i) =>
                                i === index
                                  ? { ...t, MinValue: e.target.value }
                                  : t
                              )
                            )
                          }
                          style={{ textAlign: "center" }}
                        />
                      </td>
                      <td>
                        <input
                          className={styles.tagInput}
                          type="number"
                          value={tag.MaxValue}
                          onChange={(e) =>
                            setTags((prevTags) =>
                              prevTags.map((t, i) =>
                                i === index
                                  ? { ...t, MaxValue: e.target.value }
                                  : t
                              )
                            )
                          }
                          style={{ textAlign: "center" }}
                        />
                      </td>
                      <td>
                        <button
                          className={styles.removeButton}
                          onClick={() =>
                            setTags(tags.filter((_, i) => i !== index))
                          }
                          style={{
                            backgroundColor: "#FFCC00",
                            color: "#000",
                            border: "none",
                            padding: "5px 10px",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <div className={styles.tagWindow}>
              <TagsComponent
                tags={tags}
                setTags={setTags}
                projectName={projectName}
                factoryName={factoryName}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                {errorMessages.tags && (
                  <ErrorMessage message={errorMessages.tags} />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "30px 0",
                }}
              >
                <Button2
                  type="primary"
                  className={styles.createTagButton}
                  children="Add a new tag"
                  onClick={() => setIsPopupOpen(true)}
                />
              </div>
              <AddTagPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onAddTag={handleAddTag}
                factoryName={factoryName}
                projectName={projectName}
              />
              {Object.values(errorMessages).map((error, index) => (
                <ErrorIcon key={index} error={error} />
              ))}
            </div>

            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button type="primary" children="Cancel" onClick={closeModal} />
              <div style={{ display: "flex" }}>
                {isLoading ? <div className={styles.loader}></div> : ""}
                <Button
                  type={isDisabled ? "secondary" : "primary"}
                  children="Create"
                  onClick={handleAccept}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ModalCreate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default ModalCreate;
