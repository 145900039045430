import React, { useState, useEffect } from "react";
import { thStyles } from "./ModalStyles";
import PropTypes from "prop-types";
import AddTagPopup from "./AddTagPopup";
import styles from "./ModalTagWindow.module.css";
import Button2 from "../Project/ProjectComponents/Button2";
import ErrorIcon from "./ModalTagError";
import {
  isValidProjectName,
  isValidTagName,
  isValidnewPredictionType,
} from "../../utils/validationUtils";

const TagsComponent = ({
  tags,
  setTags,
  projectName,
  factoryName,
  removedTags,
  setRemovedTags,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  const handleAddTag = (newTag) => {
    if (
      !newTag.TagName ||
      !newTag.DataType ||
      !newTag.MinValue ||
      !newTag.MaxValue
    ) {
      alert("All fields are required to create a tag.");
      return;
    }

    // Check for duplicate tag names
    const duplicateTag = tags.find((tag) => tag.TagName === newTag.TagName);
    if (duplicateTag) {
      alert("Tag with the same name already exists.");
      return;
    }

    // setTags([...tags, formattedTag]); // Add new tag to the list
    setTags([...tags, newTag]); // Add new tag to the list
  };

  const handleRemoveTag = (tag) => {
    const updatedTags = tags.filter((t) => t.TagName !== tag.TagName); // Remove the tag
    setTags(updatedTags);
    if (tag.TagId) {
      setRemovedTags([...removedTags, tag.TagId]); // Add to removed tags list
    }
  };

  const handleDataTypeChange = (id, newType) => {
    setTags((prevTags) =>
      prevTags.map((tag) =>
        tag.TagName === id ? { ...tag, DataType: newType } : tag
      )
    );
  };

  const handleMinValueChange = (id, newValue) => {
    setTags((prevTags) =>
      prevTags.map((tag) => {
        if (tag.TagName === id) {
          const newMinValue = parseFloat(newValue);
          if (newMinValue <= parseFloat(tag.MaxValue) || isNaN(newMinValue)) {
            return { ...tag, MinValue: newValue };
          }
        }
        return tag;
      })
    );
  };

  const handleMaxValueChange = (id, newValue) => {
    setTags((prevTags) =>
      prevTags.map((tag) => {
        if (tag.TagName === id) {
          const newMaxValue = parseFloat(newValue);
          if (parseFloat(tag.MinValue) <= newMaxValue || isNaN(newMaxValue)) {
            return { ...tag, MaxValue: newValue };
          }
        }
        return tag;
      })
    );
  };

  return (
    <div>
      <table className={styles.tableStyles}>
        <thead>
          <tr>
            <th th style={{ ...thStyles, width: "90%" }}>
              Name
            </th>
            <th style={thStyles}>Type</th>
            <th style={thStyles}>Minimum Value</th>
            <th style={thStyles}>Maximum Value</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag, index) => (
            <tr key={index}>
              <td>
                <input className={styles.tagB} value={tag.TagName} readOnly />
              </td>
              <td>
                <select
                  className={styles.tag}
                  value={tag.DataType}
                  onChange={(e) =>
                    handleDataTypeChange(tag.TagName, e.target.value)
                  }
                >
                  <option value="" disabled>
                    Select Prediction Type
                  </option>
                  <option value="int">int</option>
                  <option value="float">float</option>
                  <option value="boolean">boolean</option>
                </select>
              </td>
              <td>
                <input
                  className={styles.tag}
                  value={tag.MinValue}
                  onChange={(e) =>
                    handleMinValueChange(tag.TagName, e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  className={styles.tag}
                  value={tag.MaxValue}
                  onChange={(e) =>
                    handleMaxValueChange(tag.TagName, e.target.value)
                  }
                />
              </td>
              <td>
                <Button2
                  type="warning"
                  children="Remove"
                  onClick={() => handleRemoveTag(tag)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "30px 0" }}
      >
        <Button2
          type="primary"
          className={styles.createTagButton}
          children="Add a new tag"
          onClick={() => setIsPopupOpen(true)}
        />
      </div>

      <AddTagPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onAddTag={handleAddTag}
        factoryName={factoryName}
        projectName={projectName}
      />
      {Object.values(errorMessages).map((error, index) => (
        <ErrorIcon key={index} error={error} />
      ))}
    </div>
  );
};

TagsComponent.propTypes = {
  tags: PropTypes.array.isRequired,
  setTags: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
  factoryName: PropTypes.string.isRequired,
  removedTags: PropTypes.array.isRequired,
  setRemovedTags: PropTypes.func.isRequired,
};

export default TagsComponent;
