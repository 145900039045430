import React, { useState } from "react";
import styles from "./Search.module.css";
import Button from "./Button";
import { urlSearch } from "../../../Settings/url";
import { warmUpLambda } from "../../../utils/warmUpLambda";
import { isValidProjectName } from "../../../utils/validationUtils";
import { setProjectMap, ProjectOpener, setIsTableVisible } from "./ProjectList";
import { setIsTableButtonVisible } from "./DownloadDataButton";
import { useMsal } from "@azure/msal-react";

export async function getInfo(projectName, userToken) {
  const errorMessage = document.getElementById("error-message");
  errorMessage.textContent = "";
  await warmUpLambda(userToken);

  const validation = isValidProjectName(projectName);

  if (!validation.isValid) {
    errorMessage.style.color = validation.color;
    errorMessage.textContent = validation.message;
    return false;
  }

  try {
    const response = await fetch(`${urlSearch}/${projectName}`, {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    });

    // Handle 404 specifically
    if (response.status === 404) {
      console.log(`Project ${projectName} does not exist`);
      return false; // Return false to indicate project doesn't exist
    }

    // Handle other non-success statuses
    if (!response.ok) {
      const errorText = await response.text();
      console.log(errorText);
      return false;
    }

    const blob = await response.blob();
    const reader = new FileReader();

    reader.onload = function (event) {
      const data = event.target.result;
      displayData(data);
    };

    reader.readAsText(blob);
  } catch {
    errorMessage.style.color = "red";
    errorMessage.textContent = "Project not found.";
  }
}

function displayData(data) {
  const jsonDataAll = JSON.parse(data);
  console.log("Parsed data:", jsonDataAll);

  let projectMap = [];
  const errorMessage = document.getElementById("error-message");
  errorMessage.textContent = "";

  if (Array.isArray(jsonDataAll) && jsonDataAll.length === 0) {
    errorMessage.style.color = "red";
    errorMessage.textContent = "Project not found.";
    return;
  }

  jsonDataAll.forEach((jsonData, index) => {
    const projectData = jsonData.attribute_values;

    const tags = {
      TagId: projectData.PredictionId,
      TagName: projectData.TagName,
      DataType: projectData.DataType,
      MinValue: projectData.MinValue,
      MaxValue: projectData.MaxValue,
    };
    if (index === 0) {
      const projectJson = {
        ProjectId: projectData.ProjectId,
        ProjectName: projectData.ProjectName,
        Destination: projectData.Destination,
        Frequency: projectData.Frequency,
        tags: [tags],
        CreatedAt: projectData.CreatedAt,
        CreatedBy: projectData.CreatedBy,
        ModifiedAt: projectData.ModifiedAt,
        ModifiedBy: projectData.ModifiedBy,
        Status: projectData.Status,
      };
      projectMap.push(projectJson);
    } else {
      projectMap[projectMap.length - 1].tags.push(tags);
    }
  });

  setProjectMap(projectMap);
  setIsTableVisible(true);
  setIsTableButtonVisible(true);
  ProjectOpener(projectMap[0].Status);
}

const Search = () => {
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { accounts } = useMsal();
  const userToken = accounts[0].idToken;

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await getInfo(inputText, userToken);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.row}>
      <div className={styles.search}>
        <input
          id="textInput"
          type="text"
          value={inputText}
          onChange={handleInputChange}
          placeholder="Search Project Name..."
        />
      </div>
      <Button
        type={isLoading ? "secondary" : "primary"}
        children="Request Data"
        onClick={handleButtonClick}
        disabled={isLoading}
      />
      {isLoading ? <div className={styles.loader}></div> : ""}
    </div>
  );
};

export default Search;
